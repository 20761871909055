/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
/* a {
  color: #0366d6;
} */

code {
    color: #e01a76;
}


/* .btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
} */

.p-datatable .p-datatable-tbody > tr > td {
    padding: 0;
}

body {
    /* background-color: #f6f9fc */
    /* background-color: #e3edf9; */
    /* background-color: #ecf3fb; */
    background-color: #f6faff;
}

.drop-style {
    background-color: #f5f7f9 !important;
    border: none !important;
}

.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none !important;
}

.app-dropdown-group {
    vertical-align: inherit;
    display: inline-block;
}

.app-icon-btn--text .app-icon {
    width: 13px;
    vertical-align: middle;
    position: relative;
    top: -1px;
    fill: #183247;
    margin-right: 3px;
}

.app-icon-btn .app-icon {
    width: 14px;
    height: 14px;
    fill: #183247;
}

.app-icon-btn,
.app-icon-btn--text .app-icon,
.app-icon-btn--text a,
.btn--tag,
.tag-inline,
.tag-more-btn {
    display: inline-block;
}

svg:not(:root) {
    overflow: hidden;
}

.app-icon {
    fill: #12344d;
}

.app-icon {
    fill: #12344d;
}

svg {
    max-height: 100%;
}

svg {
    max-height: 100%;
}

/* *,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
} */

svg {
    width: 32px;
    height: 32px;
}

    svg:not(:root) {
        overflow: hidden;
    }

.app-icon-btn--text {
    color: #183247;
    padding: 2px 10px 3px 8px;
}

.app-icon-btn {
    height: 32px;
    min-width: 32px;
    border-radius: 4px;
    border: 1px solid #cfd7df;
    color: #12344d;
    cursor: pointer;
    padding-top: 5px;
    margin-right: 2px;
    font-size: 14px;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    transition: .1s ease-in;
    background-image: linear-gradient(to bottom, #fff, #f3f5f7);
}


/*======================================
    CUSTOM STYLE
========================================*/

.call-action.overlay::before {
    background-color: #1d4866;
}
/*.job-category .single-cat {
  border: 1px dashed #ec8922;
}*/
.header .button .btn {
    background: #0259a3;
}

.button .btn {
    background-color: #06c;
}

.scroll-top {
    background: #06c;
}

.navTitle {
    font-size: 1.2em
}



/*======================================
  HEADER
========================================*/
.header {
    background-color: #f6f9fc /*#0059b3*/;
    color: white;
}

.section-title h2 {
    text-transform: none
}

.job-category .cat-head {
    padding: 0;
}

.imgW {
    width: 70px;
}

/*======================================
  NAVBAR
========================================*/
.navbar-area {
    background-color: white;
}

.navbar-brand img {
    width: 258px;
}


/*======================================
  HERO
========================================*/
/*.hero-area.style3::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #06c;
  opacity: 0.9;
}
.hero-area.style3::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url("../images/hero/small-patern.png");
  opacity: 0.2;
}
.hero-area.style3 .hero-inner .hero-text h1 {
  color: #fff;
}
.hero-area.style3 .hero-inner .hero-text p {
  color: #fff;
}*/
.hero-area .hero-text h1 {
    font-size: 2em;
    font-weight: 700;
    line-height: 110%;
    /*    margin-bottom: 25px;
*/
}

    .hero-area .hero-text h1 span {
        font-weight: 300;
        display: inline;
    }
/*.hero-area.style3 {margin-top: 50px;}*/
.hero-area.style3 .hero-inner .hero-text {
    margin-top: 50px;
}

.hero-area.style3 .home-search {
    margin-top: 50px;
}

.hero-area.style3 .hero-inner {
    height: 520px;
}

.section {
    padding-top: 50px;
    padding-bottom: 50px;
}
.section-title {
    margin-bottom: 50px;
    padding: 0 100px;

}
.job-category .single-cat h3 {
    font-size: 25px;
}
.job-details .job-details-inner {
    padding: 40px;
    margin: 30px;
    margin-top: 80px;
}
/*======================================
  SERVIZI PRIMARI
========================================*/
.apply-process {
    background-color: #164e6e;
    border-top: 4px solid #ec8922;
    padding: 40px 0;
    margin-top: 80px;
}

    .apply-process .process-item h4 {
        font-size: 28px;
        /*    color: #fff;
  position: relative;
  display: block;
  margin-bottom: 20px;
  padding-bottom: 20px;*/
    }

        .apply-process .process-item h4 a {
            color: #fff;
        }

    .apply-process .process-item img {
        position: absolute;
        left: 0;
        top: 0;
        height: 65px;
        width: 65px;
        border: 1px dashed #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 62px;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }

.apply-process2 {
    padding: 0;
    margin-top: 80px;
    background-color: #06c;
    border-top: 4px solid #ec8922;
    color: white
}

/*======================================
  FOOTER
========================================*/

.footer .single-footer h3 {
    text-transform: none;
}

/*======================================
  NEWS
========================================*/
.latest-news-area .single-news .content-body .title {
    font-size: 26px;
    line-height: 28px;
    margin-bottom: 18px;
    padding-bottom: 15px;
    position: relative;
    font-weight: 500;
}


@media only screen and (max-width: 767px) {
    .hero-area.style3 {
        text-align: center;
    }

    .navbar-collapse {
        height: auto;
    }

    .section-title h2 {
        font-size: 35px;
        margin-bottom: 18px;
        line-height: 45px;
        text-transform: capitalize;
        position: relative;
        font-weight: 700;
        padding-bottom: 14px;
    }
}


/* .button-home a:hover {
    color: #aaaaaa;
} */

.css-1okebmr-indicatorSeparator {
    display: none !important;
}

.navbar-toggler {
    display: none;
}

.navbar-utd{
display:flex;
}

.back-to-console {
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.03em;
    font-weight: 500;
  }
  
  .back-to-console-link:hover {
    opacity:.8;
  }
  
  .back-to-console-ico {
    font-size: 17px!important;
  }

  .spid-button img {
    min-height: 30px;
}


  @media only screen and (max-width: 767px) {
    .navbar-utd{flex-direction: column;}
    .utd-sub-title {font-size: 10px;}
    .back-to-console{
    display:none;
  }
  .back-to-console-ico {
    font-size: 24px !important;
  }
}